import React, { useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { getTimeDifference, utcToSGDateTimeStr } from '../../utils/datetimeHelper';
import { loadFromLocalStorage } from '../../utils/storageHelper';
import { FormatString } from '../../utils/stringHelper';
import storageConst from '../../constants/storage';
import redirectUrlConst from '../../constants/redirectUrlConst';

function NotificationPopup(props) {
    const [currentUser] = useState(loadFromLocalStorage(storageConst.CURRENT_USER));

    useEffect(() => {}, []);

    const handleViewAllClick = (e) => {
        e.preventDefault();

        let pageUrl = FormatString(redirectUrlConst.NotificationUrl);

        window.open(pageUrl, '_blank');
    };

    const handleRedirectToModule = (e, item) => {
        e.preventDefault();

        const paramData = JSON.parse(item.paramJson);
        const date = paramData.Date;
        const userName = paramData.UserName;

        let pageUrl = FormatString(redirectUrlConst.InternalAuditUrl, date, userName);

        window.open(pageUrl, '_blank');
    };

    return (
        <>
            <div className="d-sm-flex align-items-center justify-content-between mb-3">
                <h4 className="text-black-800">Notifications</h4>
            </div>

            {props.data.length > 0 && (
                <div className="d-sm-flex align-items-center justify-content-between mb-2">
                    <label className="m-0 font-weight-bold text-gray-800">Latest</label>
                </div>
            )}

            {props.data.map((item, index) => (
                <div key={index} className="notification-item-container row py-2" style={{ borderBottom: '1px solid gray' }} onClick={(e) => handleRedirectToModule(e, item)}>
                    <div className="col-sm-11">
                        <label className="m-0 font-weight-bold text-black-800">{item.title}</label>
                        <br />
                        <label className="m-0 font-weight-bold text-gray-600">{getTimeDifference(utcToSGDateTimeStr(item.createdAt))}</label>
                        <br />
                        <label className="m-0 text-black-800">{item.content}</label>
                    </div>
                    <div className="col-sm-1">{item.isRead === 0 ? <span className="notification-dot"></span> : ''}</div>
                </div>
            ))}

            <div className="col-sm-12 text-center">
                <button type="button" onClick={(e) => handleViewAllClick(e)} className={`btn btn-link gblink mt-1`}>
                    View All
                </button>
            </div>
        </>
    );
}

NotificationPopup.propTypes = {
    data: PropTypes.array,
};

export default NotificationPopup;
